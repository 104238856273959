import React from "react"
import styled from "styled-components"
import Heading from "../Heading"
import BaseColumn from "../MainColumn"
import PhoneIcon from "../../images/phone-icon.svg"
import ShareIcon from "../../images/share-icon.svg"
import CartIcon from "../../images/cart-icon.svg"
import { palette, theme } from "styled-tools"

const Section = styled.section`
  background-color: ${palette("white")};
`

const MainColumn = styled(BaseColumn)`
  padding: 6.4rem;
`

const Items = styled.ul`
  display: grid;
  margin: 6.4rem;
  grid-gap: 4.8rem;
  margin-top: 6.4rem;
  grid-template-columns: repeat(3, 1fr);
`

const Item = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const ItemIcon = styled.div`
  display: flex;
  width: 8.4rem;
  height: 8.4rem;
  border-radius: 50%;
  align-items: center;
  margin-bottom: 3.2rem;
  justify-content: center;
  background-color: ${palette("primary", 1)};
`

const ItemText = styled.p`
  width: 70%;
  text-align: center;
  line-height: 1.6em;
  font-weight: ${theme("fontWeight.bold")};
  font-family: ${theme("fontFamily.secondary")};
`

function Offers() {
  const items = [
    { text: "Navegue e realize seus pedidos em nosso site através de qualquer aparelho", icon: PhoneIcon },
    { text: "Convide seus amigos e receba recompensas e descontos exclusivos em todos nossos produtos", icon: ShareIcon },
    { text: "Escolha o combo que mais se adequa a sua necessidade, recebendo no conforto de sua casa", icon: CartIcon }
  ]

  return (
    <Section>
      <MainColumn>
        <Heading title="O que oferecemos" />
        <Items>
          {items.map(({ text, icon: Icon }, index) => (
            <Item key={index}>
              <ItemIcon>
                <Icon />
              </ItemIcon>
              <ItemText>{text}</ItemText>
            </Item>
          ))}
        </Items>
      </MainColumn>
    </Section>
  )
}

export default Offers
