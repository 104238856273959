import React from "react"
import Card from "./Card"
import styled from "styled-components"
import Heading from "../Heading"
import BaseColumn from "../MainColumn"
import FirstStepIcon from "../../images/welcome-first-step-icon.svg"
import SecondStepIcon from "../../images/welcome-second-step-icon.svg"
import ThirdStepIcon from "../../images/welcome-third-step-icon.svg"

const MainColumn = styled(BaseColumn)`
  padding: 6.4rem;
`

const Cards = styled.div`
  display: grid;
  grid-gap: 4.4rem;
  margin: 4.8rem 1rem 0;
  grid-template-columns: repeat(3, 1fr);
`

function Welcome() {
  return (
    <section>
      <MainColumn>
        <Heading
          title="Bem-vindo ao nosso sistema que foi criado pensando em você!"
          description="Proporcionamos consumo inteligente de alimentos e produtos de higiene, buscando facilitar sua vida entregando qualidade, pontualidade, satisfação e inovação para todos nossos clientes."
        />
        <Cards>
          <Card title="Escolha uma de Nossas Cestas" image={FirstStepIcon} />
          <Card title="Preencha o Formulário para Pagamento" image={SecondStepIcon} />
          <Card title="Receba a Entrega em Sua Casa" image={ThirdStepIcon} />
        </Cards>
      </MainColumn>
    </section>
  )
}

export default Welcome
