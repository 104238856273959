import React from "react"
import styled from "styled-components"
import { palette, theme } from "styled-tools"

const Wrapper = styled.article`
  width: 100%;
  display: flex;
  padding: 3rem 4rem;
  position: relative;
  align-items: center;
  flex-direction: column;
`

const WhiteBackground = styled.div`
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 20rem;
  position: absolute;
  border-radius: 10px;
  background-color: ${palette("white")};
  box-shadow: ${theme("boxShadow.main")};
`

const Title = styled.h1`
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 2.4rem;  
  text-transform: uppercase;
  color: ${palette("primary")};
`

function Card({ title, image: Image }) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Image />
      <WhiteBackground />
    </Wrapper>
  )
}

export default Card
