import React from "react"
import Layout from "../Layout"
import Banner from "../Banner"
import Seo from "../seo"
import Welcome from "./Welcome"
import Offers from "./Offers"
import Callback from "../Callback"
import { useStaticQuery, graphql } from "gatsby"

function Home() {
  const { file } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "home-banner-image.png" }) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="Início" />
      <Banner file={file} />
      <div id="content">
        <Welcome />
        <Offers />
        <Callback />
      </div>
    </Layout>
  )
}

export default Home
